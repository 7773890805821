<template>
<FAQ v-if="showFAQ" @close="toggleFAQ" />
<Dropdown 
	v-if="showDropdown" 
	:message="selectedMessage"
	:anchor="selectedMessageElement"

	@block="_handleBlock"
	@unblock="_handleUnblock"
	@pin="_handlePin"
/>
<div class="chat-wrapper" id="exp-wrapper[chat]">
	<!-- chat wrapper -->
	<LiveStream v-if="store.livestreaming && store.mobileDimensions"/>
	<div class="chat">
		<br class="desktop-only" />
		<br class="desktop-only" />

		<div v-if="store.state === 1" class="mobile-only" style="text-align: center; color: #fff">
			<img :src="store.tunecast.chat_logo_image" style="height: 5.5rem" id="exp-image[mobile-chat-logo]">
			<br />
			<p style="margin-top: .5rem; margin-bottom: 1rem;"><b>Show Starts in {{store.countdownStr}}</b></p>
		</div>
		<div v-if="store.state === 2" class="mobile-only" style="box-shadow: -5px 0 30px 10px #000; padding-top: 10px; background-color: #000; text-align: center; color: #fff;" >
			<h4 style="font-weight: 500;">{{store.tunecastName}}</h4>
			<p style="font-weight: 200; font-size: 14px; color: rgba(255,255,255,.8); margin-top: .25rem;">{{ store.playlistTracks.length }} {{LOCALE[store.tunecast.lang]['SONGS']}}</p>
			<br />

			<MobileTracklist />

			<br />

			<!-- <div style="position: relative">
				<div class="mobile-only mobile-divider"></div>

				<div class="on-air-wrap-outer">
					<div class="on-air-wrap-inner">
						<p class="on-air" v-if="store.state === 2"><img src="/svgs/ellipse.svg" style="height: .7rem; margin-right: .35rem;"> ON AIR</p>
						<p class="off-air" v-if="store.state !== 2"><img src="/svgs/off-ellipse.svg" style="height: .7rem; margin-right: .35rem;"> OFF AIR</p>
					</div>
				</div>
			</div> -->
		</div>

		<br class="desktop-only" />
		<div class="desktop-only" style="display: flex; justify-content: space-between;">
			<!-- menu -->
			<button class="btn btn-gray" :style="`width: 49%; ${hostOnly ? `color: gold ` : ''}`" @click="toggleHostOnly">{{ LOCALE[store.tunecast.lang]['FILTERMESSAGES'] }}</button>
			<button class="btn btn-gray" style="width: 49%" @click="toggleFAQ">{{ LOCALE[store.tunecast.lang]['FAQHELP'] }}</button>
		</div>

		<br class="desktop-only" />

		<div class="mobile-carousel" ref="carouselElement">
			<ShopifyBlock style="background-color: #000;" v-if="store.tunecast.shopify" />

			<div class="chat-block">
				<div class="chat-messages" ref="messagesElement">
					<!-- chat messages -->
					<div :class="`chat-message ${(selectedMessage && selectedMessage._id && m._id === selectedMessage._id) ? 'inspecting' : ''}`" 
						v-for="(m, key) in filterMessages(messages)" 
						:key="key"
						:style="key === 0 && 'margin-top: auto;'"
						@click="e => inspectMessage(e, m)"
					>
						<!-- message -->
						<p class="chat-message-username" :style="m.nameColor && `color: ${m.nameColor}`">{{m.name}}</p>
						
						<VoiceBubble v-if="m.audioUrl" :audioUrl="m.audioUrl" />
						<p v-if="m.msg" class="chat-message-text" :style="m.chatColor && `color: ${m.chatColor}`">{{m.msg}}</p>
					</div>
				</div>

				<br />
				<div class="input-padding">
				<div class="input-wrapper">
					<!-- <div class="image-group">
						<div class="btn-x"></div>
						<img src="https://i.imgur.com/0J3Nlul.jpg">
					</div> -->
					<div class="input-group">
						<!-- input -->

						<input v-if="!isRecording" class="form-control" :placeholder="LOCALE[store.tunecast.lang]['ADDMESSAGE']" v-model="msg" @keyup="_handleKeyPress">
						<p v-else class="form-control">Recording audio...</p>

						<button 
							v-if="isArtist"
							class="mic-btn"
							@mousedown="startRecording"
							@mouseup="stopRecording"
							@mouseleave="cancelRecording"
							@touchstart.prevent="startRecording"
							@touchend.prevent="stopRecording"
							@touchcancel.prevent="cancelRecording"
						>
							<i class="fa fa-microphone"></i>
						</button>

						<p class="input-btn" @click="_handleSend">
							<i class="fa fa-send"></i>
							<!-- {{ LOCALE[store.tunecast.lang]['SEND'] }} -->
						</p>
					</div>
				</div>

				<ul class="tos-ul">
					<li><a :href="store.tunecast.custom_terms" target="_blank">Terms of Service</a></li>
					<li><a :href="store.tunecast.custom_privacy" target="_blank">Privacy</a></li>
				</ul>

				</div>

			</div>


			<MobileSettingsBlock 
				:hostOnly="hostOnly" 
				@toggleHost="toggleHostOnly" 
			/>


		</div>

		<div class="page-dots">
			<div v-if="store.tunecast.shopify" :class="`dot ${carouselPage === 1 ? 'active' : ''}`"></div>
			<div :class="`dot ${(!store.tunecast.shopify && carouselPage === 1) || (store.tunecast.shopify && carouselPage === 2) ? 'active' : ''}`"></div>
			<div :class="`dot ${(!store.tunecast.shopify && carouselPage === 2) || (store.tunecast.shopify && carouselPage === 3) ? 'active' : ''}`"></div>
		</div>
	</div>
</div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from '@/store'
import LOCALE from '@/services/locale';
const store = useStore()
import axios from 'axios';

import LiveStream from './LiveStream';
import VoiceBubble from './VoiceBubble';

import MobileTracklist from './MobileTracklist.vue';
import MobileSettingsBlock from './MobileSettingsBlock.vue'
import ShopifyBlock from './MobileShopifyBlock.vue';
import FAQ from './FaqModal.vue';
import Dropdown from '@/components/Dropdown.vue';

import { getNamespace } from '@/utils';

const showFAQ = ref(false);
const showDropdown = ref(false);

const selectedMessage = ref({});
const selectedMessageElement = ref({});

const carouselElement = ref({});
const messagesElement = ref({});
const isArtist = ref(false);
const isMod = ref(false);
const modCode = ref('');
const artistCode = ref('');
const messages = ref([]);
// the actual messages that are rendered
const pinnedMessage = ref({});
const msg = ref('');
const hostOnly = ref(false);

import { BACKEND_URL } from "@/services/config";

const carouselPage = ref(store.tunecast.shopify ? 2 : 1);
console.log('the page is', carouselPage.value)

const chatScrollToBottom = () => {
	setTimeout(() => messagesElement.value.scrollTop = messagesElement.value.scrollHeight, 30);
}

const toggleFAQ = () => showFAQ.value = !showFAQ.value;
const toggleHostOnly = () => {
	hostOnly.value = !hostOnly.value;
	chatScrollToBottom();
}
const filterMessages = messages => messages.filter(m => hostOnly.value ? m.nameColor && m.nameColor.length : true);

let mobileView = false;
window.addEventListener('resize', resize)

function resize() {
	// start mobile carousel on chat view
	if(window.innerWidth > 900)
		return mobileView = false;
	
	if(mobileView) return;
	mobileView = true;

	if(!store.tunecast.shopify) return;
	carouselPage.value = 2;
	carouselElement.value.scrollLeft = window.innerWidth * .66;
}

onMounted(async () => {
	resize();

	carouselElement.value.addEventListener('scroll', () => {
		// Grab the position yo are scrolled to (the top of the viewport)
		let pos = carouselElement.value.scrollLeft;
		let width = window.innerWidth;
		if(pos >= width + width * .5)
			carouselPage.value = 3;
		else if(pos >= width * .5)
			carouselPage.value = 2;
		else
			carouselPage.value = 1;

	});

	let res;
    try {
      res = await fetch(`${BACKEND_URL}/chat-hist?ns=${getNamespace()}`);
    } catch (err) {
      return console.log("couldnt fetch chat hist");
    }
    let data = await res.json();
    let hist = data.history.reverse();
    for(let j = 0; j < hist.length; j++)
		messages.value.push(hist[j]);

	// delay to allow for elements to load
	chatScrollToBottom();
})

let socket = window.socket;
function registerSocketEvents() {
	socket.emit("joined", 1);

	socket.on('modcode', data => {
		let _data;
		if(data) {
			_data = {
				name: '',
				msg: 'Moderation enabled.',
				chatColor: '#9933ff'
			}

			isMod.value = true;
		} else {
			_data = {
				name: '',
				msg: 'Moderation code invalid.',
				chatColor: 'red'
			}
		}

		messages.value.push(_data);
	})

	socket.on('clearchat', () => {
		messages.value = [];
	})

    socket.on("chat_msg", (data) => {
		messages.value.push(data);
		if (
			Math.abs(messagesElement.value.scrollHeight -
				(messagesElement.value.scrollTop + messagesElement.value.clientHeight)) 
			< 120
		)
			chatScrollToBottom();
    });

    socket.on("pin_msg", (data) => {
		pinnedMessage.value = data;
    });

    socket.on("remove_msg", (data) => {
		// remove the message
		for (let j = 0; j < messages.value.length; j++) {
			if (messages.value[j]._id === data._id) {
				messages.value.splice(j, 1);
				break;
			}
		}
    });

    // socket.on("updateState", (data) => {
    //   let state;

    //   switch (data.state) {
    //     case this.TUNECAST_STATE.COUNTDOWN:
    //       state = this.TUNECAST_STATE.COUNTDOWN;
    //       break;
    //     case this.TUNECAST_STATE.PLAYER:
    //       state = this.TUNECAST_STATE.PLAYER;
    //       break;
    //     case this.TUNECAST_STATE.END:
    //       state = this.TUNECAST_STATE.END;

    //       this.endLoop();
    //       break;
    //   }

    //   this.setState({
    //     STATE: state || this.state.STATE,
    //     startTime: data.startTime || this.state.startTime,
    //   });
    // });

    // socket.on("startTime", (data) => {
    //   this.setState({
    //     startTime: data.startTime,
    //     STATE: this.TUNECAST_STATE.COUNTDOWN,
    //   });
    // });
}

const inspectMessage = (e, msg) => {
	if(!isMod.value) return;
	let elem;
	if(e.target.className === 'chat-message')
		elem = e.target;
	else
		elem = e.target.parentNode;

	if(selectedMessage.value._id === msg._id) return resetDropdown()
	if(!msg._id) return resetDropdown();

	selectedMessage.value = msg;
	selectedMessageElement.value = elem;
	showDropdown.value = true;
}


// when user sends message
const _handleKeyPress = (e) => {
	if (e.key !== "Enter") return;
	if (msg.value === "") return;

	_handleSend();
}

const _handleSend = () => {
	// handle white space
	if(/^\s*$/.test(msg.value)) return msg.value = '';

	let data = {
		name: store.username,
		msg: msg.value,
		...isArtist.value ? { chatColor: '#fff', nameColor: 'lightblue' } : {}
	};

	socket.emit("chat_msg", data);

	if(msg.value.indexOf('tunecastartist') > -1) {
		isArtist.value = true;
		artistCode.value = msg.value;
		data = {
			name: '',
			msg: 'Featured user enabled.',
			chatColor: '#9933ff'
		}
	}

	// dont push mod code to user's chat
	if(!(msg.value.indexOf('modcode') > -1)) 
		messages.value.push(data);
	else
		modCode.value = msg.value;
	msg.value = '';
	chatScrollToBottom();
}

const resetDropdown = () => {
	selectedMessage.value = {};
	selectedMessageElement.value = {};
	showDropdown.value = false;
}

const _handleBlock = async () => {
    if (!selectedMessage.value) return;
	let _id = selectedMessage.value._id;

	await fetch(`${BACKEND_URL}/mod/block?ns=${getNamespace()}`, {
		method: "POST",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			_id,
			secret: modCode.value,
		}),
	});
	// let data = await res.json();
	resetDropdown()
}	

const _handleUnblock = async () => {
    if (!selectedMessage.value) return;
	let _id = selectedMessage.value._id;

    await fetch(`${BACKEND_URL}/mod/unblock?ns=${getNamespace()}`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id,
        secret: modCode.value,
      }),
    });
    resetDropdown()
  }

const _handlePin = async () => {
    if (!selectedMessage.value) return;
	let _id = selectedMessage.value._id;

    await fetch(`${BACKEND_URL}/mod/pin?ns=${getNamespace()}`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id,
        secret: modCode.value,
      }),
    });

    resetDropdown()
}

/** voice notes */
const audioUrl = ref(null);
const mediaRecorder = ref(null);
let audioChunks = [];
const isRecording = ref(false);

function startRecording() {
	console.log('recording... dsp is', store.DSP)
    if(store.DSP === 'apple')
        window.apple_player.volume = 0.01;
    else
        window.spotify_player.setVolume(0.01);

	navigator.mediaDevices.getUserMedia({ 
		sampleRate: 44100,
		channelCount: 2,
		audio: {
			echoCancellation: false,
			noiseSuppression: false,
			autoGainControl: false
		}
	})
    .then(stream => {
		mediaRecorder.value = new MediaRecorder(stream, { 
			mimeType: 'audio/webm',
			audioBitsPerSecond: 128000 // 128 kbps
		});
      mediaRecorder.value.start();
      audioChunks = [];
		isRecording.value = true;

      mediaRecorder.value.ondataavailable = event => {
		console.log('datas', event.data);
        audioChunks.push(event.data);
		console.log('pushed the chunk', audioChunks)
      };
    })
    .catch(error => console.error('Error accessing the microphone:', error));
}

let lastStopped = 0;
async function stopRecording() {
	// timeout
	lastStopped = Date.now();
	mediaRecorder.value.stop();
	await new Promise(res => setTimeout(res, 2000));
	console.log('now finishng up')

  isRecording.value = false;
  mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
  let audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
  const formData = new FormData();
	await new Promise(res => {
		async function check() { 
			console.log('blob size!', audioBlob.size);
			while(audioBlob.size === 0) {
				console.log(audioChunks, audioChunks.length)
				audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
				console.log('blob size!', audioBlob.size);
				await new Promise(res => setTimeout(res, 200));
			}

			res();
		}

		check();
	})

	const louderAudioBlob = await increaseAudioVolume(audioBlob, 3);

  formData.append('voiceNote', louderAudioBlob);
  audioChunks = [];
  
  axios.post(BACKEND_URL + '/upload?code=' + artistCode.value, formData)
    .then(response => {
		console.log(response);
		audioUrl.value = response.data.file;
		console.log('got url!', audioUrl.value)
		
		const _data = {
			name: store.username,
			audioUrl: audioUrl.value,
			chatColor: 'red'
		}

		messages.value.push(_data);
		socket.emit('voicenote', {
			name: store.username,
			audioUrl: audioUrl.value
		});
		chatScrollToBottom();
    })
    .catch(error => console.error('Upload failed:', error));


    if(store.DSP === 'apple')
        window.apple_player.volume = 1;
    else
        window.spotify_player.setVolume(1);
}

function cancelRecording() {
	if(Math.abs(lastStopped - Date.now()) < 1000) return;
	if(!mediaRecorder.value) return;
	console.log('cancelng!')
  mediaRecorder.value.stop();
	isRecording.value = false;
  mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
  audioChunks = [];

  if(store.DSP === 'apple')
        window.apple_player.volume = 1;
    else
        window.spotify_player.setVolume(1);
}

registerSocketEvents()


async function increaseAudioVolume(audioBlob, gainValue) {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  // Decode the audio data from the blob
  const arrayBuffer = await audioBlob.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Create an offline audio context to process the audio
  const offlineContext = new OfflineAudioContext(
    audioBuffer.numberOfChannels,
    audioBuffer.length,
    audioBuffer.sampleRate
  );

  // Create a buffer source node
  const bufferSource = offlineContext.createBufferSource();
  bufferSource.buffer = audioBuffer;

  // Create a gain node
  const gainNode = offlineContext.createGain();
  gainNode.gain.value = gainValue; // Increase the gain value to make the audio louder

  // Connect the nodes
  bufferSource.connect(gainNode);
  gainNode.connect(offlineContext.destination);

  // Start processing the audio
  bufferSource.start(0);
  const renderedBuffer = await offlineContext.startRendering();

  // Convert the processed audio buffer back to a blob
  const processedBlob = audioBufferToBlob(renderedBuffer);
  return processedBlob;
}

function audioBufferToBlob(audioBuffer) {
  const numberOfChannels = audioBuffer.numberOfChannels;
  const length = audioBuffer.length * numberOfChannels * 2 + 44; // 16-bit PCM format
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);

  // Write WAV header
  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  let offset = 0;
  writeString(view, offset, 'RIFF');
  offset += 4;
  view.setUint32(offset, 36 + audioBuffer.length * numberOfChannels * 2, true);
  offset += 4;
  writeString(view, offset, 'WAVE');
  offset += 4;
  writeString(view, offset, 'fmt ');
  offset += 4;
  view.setUint32(offset, 16, true);
  offset += 4;
  view.setUint16(offset, 1, true);
  offset += 2;
  view.setUint16(offset, numberOfChannels, true);
  offset += 2;
  view.setUint32(offset, audioBuffer.sampleRate, true);
  offset += 4;
  view.setUint32(offset, audioBuffer.sampleRate * 2 * numberOfChannels, true);
  offset += 4;
  view.setUint16(offset, numberOfChannels * 2, true);
  offset += 2;
  view.setUint16(offset, 16, true);
  offset += 2;
  writeString(view, offset, 'data');
  offset += 4;
  view.setUint32(offset, audioBuffer.length * numberOfChannels * 2, true);
  offset += 4;

  // Write PCM samples
  for (let i = 0; i < audioBuffer.length; i++) {
    for (let channel = 0; channel < numberOfChannels; channel++) {
      const sample = Math.max(-1, Math.min(1, audioBuffer.getChannelData(channel)[i]));
      view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
      offset += 2;
    }
  }

  return new Blob([buffer], { type: 'audio/wav' });
}

</script>
<style type="text/css" scoped>
/**
 *
 * CHAT
 *
 */


.chat-wrapper {
	flex: 1;
	height: 100%;
	background-color: #000;
	position: relative;
	z-index: 3900;
	overflow: hidden;
}

.chat {
	width: 350px;
	float: right;
	margin-right: 3rem;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.chat-messages {
	--mask: linear-gradient(to top, 
		rgba(0,0,0, 1) 70%, 
		rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
	) 100% 50% / 100% 100% repeat-x;

	-webkit-mask: var(--mask); 
	mask: var(--mask);
	flex: 1;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
}

.chat-message {
	margin-bottom: .5rem;
	transition: .15s ease opacity;
}

.chat-message.inspecting {
	color: red !important;
}

.chat-message:hover {
	opacity: .7;
	cursor: pointer;
}


.chat-message-username {
	font-weight: 600;
	font-size: 14px;
	margin-bottom: -.2rem;
}

.host-message {
	text-align: right;
}

.host-message .chat-message-username {
	color: #F3DC79;
}

.chat-message-text {
	font-weight: 200;
	font-size: 14px;
}

.input-wrapper {
	margin-bottom: .5rem;
	border-radius: 5px;
	outline: none !important;

	color: #000;

	/* background-color: rgba(255,255,255,.2); */
	background-color: #fff;
	box-sizing: border-box;
	border-radius: 30px;

	width: 100%;
	border: none;
}

.input-wrapper .image-group {
	padding: 1.2rem 1.2rem;
	padding-bottom: 0;
	position: relative;
}

.btn-x {
	margin-top: 0.25rem;
    margin-left: 0.25rem;
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background-color: #777;
    display: inline-block;
    border: 2px solid #fff;
}

.input-wrapper .image-group img {
	height: 6rem;
	border-radius: 5px;
}

.input-wrapper .input-group {
	box-sizing: border-box;
	/*border: none;*/
	/*padding: .9rem 1.2rem;*/
	/*border-radius: 5px;*/
	/*outline: none !important;*/

	display: flex;
	align-items: center;
	/*background-color: rgba(255,255,255,.2);*/

	width: 100%;

}


.form-control {
	background: none;
	padding: 1.2rem 1.2rem;
	outline: none;
	flex: 1;
	width: 100%;
	box-sizing: border-box;
	color: #000;
	font-size: 16px;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #555;
  /* font-weight: 300; */
}

.input-btn {
	font-weight: 600;
	/* color: #F3DC79; */
	color: #000;
	border: 1px solid #000;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 1rem .25rem; */
	margin-right: 1rem;
	transition: .1s ease all;
}

.input-btn:hover {
	color: rgba(100,100,100,.9);
	cursor: pointer;
}

.tos-ul {
	text-align: center;
	padding-bottom: 1rem;
}
.tos-ul li {
	display: inline;
	color: #fff!important;
	margin: 0 .5rem;
	width: 100%;
	font-size: 14px;
}

.tos-ul li a {
	color: rgba(255,255,255,.9);
}

/* ============
 * 
 * other stuff
 *
 * =============
 */
.btn {
	background: rgba(0,0,0,.5);
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: .6rem 1.65rem;
	transition: .1s ease all;
}

.btn:hover {
	opacity: .85;
	cursor: pointer;
}

.btn-gray {
	padding: .3rem .2rem;
	background: rgba(255,255,255,.2)
}

.btn.active {
	background: #000;
}

/**
 * 
 * MOBILE
 *
 */

.mobile-divider {
	position: absolute; 
	left: -50px; 
	width: 140%; 
	height: .15rem; 
	top: 10px; 
	background-color: #17171B; 
	z-index: -5;
}

.on-air {
	display: flex; 
	align-items: center; 
	justify-content: center; 
	text-align: center; 
	color: #E34141; 
	font-weight: 600
}

.off-air {
	display: flex; 
	align-items: center; 
	justify-content: center; 
	text-align: center; 
	color: #C5C5C5; 
	font-weight: 600

}

.resync-btn {
	align-items: center; 
	justify-content: center; 
	text-align: center;
	font-weight: 600;
}

.resync-btn img {
	height: 9px;
	margin-right: .3rem;
}

.on-air-wrap-outer, .on-air-wrap-inner {
	position: relative; 
	justify-content: center; 
	align-items: center; 
	display: flex; 
	flex-direction: row; 
}

.on-air-wrap-outer {
	width: 100%;
}

.on-air-wrap-inner {
	padding: 0 .8rem; 
	/*background-color: #000; */
	gap: 20px;
}


.mobile-tracklist {
	position: relative; 
	width: 100vw; 
	/* left: -2rem;  */
	display: flex; 
	justify-content: left; 
	gap: 1rem; 
	overflow: scroll;
}

.mobile-tracklist::-webkit-scrollbar {
  display: none;
} 

#mobile-tracklist-buffer {
	display: inline-block; 
	min-width: 1rem; 
	height: 1rem; 
	background-color: #000;
}


.mobile-only {
	position: relative;
	display: none!important;
}

.mobile-carousel {
	position: relative;
	flex: 1;
	overflow: hidden;
	z-index: 5;
}

.mobile-carousel::-webkit-scrollbar {
  display: none;
} 

.page-dots {
	position: absolute;
	width: 100vw;
	bottom: 0;
	z-index: 5000;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: .27rem;
	margin-bottom: 1rem;
	margin-top: -.75rem;
	display: none;

}

.page-dots .dot {
	height: .52rem;
	width: .52rem;
	background-color: rgba(255,255,255,.3);
	border-radius: 50%;
}

.page-dots .dot.active {
	background-color: rgba(255,255,255);
}

.chat-block {
	/*flex: 1;*/
	scroll-snap-align: center;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.mic-btn {
	margin-left: -1rem; 
	margin-right: .5rem;
	background: none;
	color: #000;
	font-size: 16px;
	position: relative;
	transition: .2s ease color;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;
	
	border: 1px solid #000;
	border-radius: 50%;
	height: 37px;
	width: 37px;
}

.mic-btn:hover {
	color: rgba(100,100,100,.9);
}

@media screen and (max-width: 960px) {
	.tracklist {
		width: 448px;
	}
}

@media screen and (max-width: 900px) {
	.desktop-only {
		display: none!important;
	}

	.mobile-carousel {
		padding: 0 2rem;
	}

	.mobile-only {
		display: initial!important;
	}

	.input-padding {
		padding: 0 2rem;
	}

	.resync-btn {
		display: flex!important;
	}

	.wrapper {
		height: 100vh;
	}
	.content {
		display: none;
	}

	.player {
		display: none;
	}

	.chat-wrapper {
		/* padding: 0 2rem; */
	}
	.chat {
		width: 100%;
		float: none;
	}

	/**
	* mobile carousel 
	*/
	.mobile-carousel {
		width: 100vw;
		left: -2rem;
		display: flex;
		justify-content: left;
		gap: 1rem;
		overflow-x: scroll;
		overflow-y: hidden;
		scroll-snap-type: x mandatory;
	}
	.chat-block {
		min-width: 100vw;
	}

	.chat-messages {
		padding: 0 2rem;
	}

	.page-dots {
		display: flex;
	}
}
</style>