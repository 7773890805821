<template>
<div class="player">
	<!-- player -->
	<div class="now-playing-meta" v-if="state === 2">
		<!-- now playing -->
		<p>{{LOCALE[store.tunecast.lang]['NOWPLAYING']}}</p>

		<div class="current-track">
			<img :src="currentTrack.artwork">

			<div class="current-track-meta">
				<h3 v-if="currentTrack && currentTrack.name">{{truncate(currentTrack.name)}}</h3>
				<h3>{{currentTrack.artist}}</h3>
			</div>
		</div>
	</div>

	<div v-if="state === 2" style="display: flex; flex-direction: row; flex: 1; align-items: center; margin: 0 7.5rem 0 2.4rem">
		<div class="player-time">{{formatTime(currentTrack.progress)}}</div>
		<div class="player-bar-wrapper">
			<div class="player-bar-ball" :style="`left: ${(currentTrack.progress / currentTrack.duration) * 100 - 1}%`"></div>
			<div class="player-bar-progress" :style="`width: ${(currentTrack.progress / currentTrack.duration) * 100}%`"></div>
			<div class="player-bar"></div>
		</div>
		<div class="player-time">{{formatTime(currentTrack.duration)}}</div>
	</div>
</div>
</template>
<style scoped>
	.footer {
		position: absolute;
		bottom: 1rem;
		right: .5rem;
	}

	.footer li {
		display: inline;
		margin-right: .5rem;
		font-size: .85rem;
	}

	.footer li a {
		color: #000;
		transition: .2s ease all;
	}

	.footer li a:hover {
		opacity: .8;
	}
</style>
<script setup>
import { storeToRefs } from 'pinia';
import { formatTime } from '@/utils';
import { useStore } from '@/store'
import { usePlayerStore } from '@/store/player';
import LOCALE from '@/services/locale';

const truncate = str => str.length > 27 ? str.slice(0,26) + '...' : str;
// import { play } from '@/services/spotify-service';
const store = useStore();
const { state } = storeToRefs(store)
const playerStore = usePlayerStore();
const { currentTrack } = storeToRefs(playerStore);
// let apple_player = window.apple_player;


// WEB-SDK Compatible

// watch(state, (newState, oldState) => {
// 	console.log('state updating', newState, oldState)
// 	if(!(newState === 2 && oldState !== newState)) return;
// 	// countdown just finished
// 	playAudio();
// })

/** 
 * update current track
 */
// setInterval(() => {
// 	let { uri, progress } = getTrack(store.tunecast.start_time);
// 	let track = store.playlistTracks.find(t => t.spotify_id === uri.split(':')[2]);
// 	if(track.spotify_id !== currentTrack.value.spotify_id)
// 		playerStore.currentTrack = track;

// 	currentTrack.value = {
// 		name: track.name,
// 		artist: track.artist,
// 		duration: track.duration,
// 		artwork: track.artwork,
// 		spotify_id: track.spotify_id,
// 		progress
// 	}
// }, 1000)

</script>

<style type="text/css" scoped>

.player {
	position: fixed;
	bottom: 0;
	height: 4.8rem;
	width: 100vw;
	background-color: #fff;
	z-index: 3500;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.now-playing-meta {
	padding: .5rem 1.5rem;
	margin-right: 3rem;
}

.now-playing-meta p {
	color: #C5C5C5;
	font-weight: 600;
	font-size: 8px;
	margin-bottom: .2rem;
}

.now-playing-meta .current-track {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.now-playing-meta .current-track img {
	height: 2.8rem;
}
.now-playing-meta .current-track-meta {
	color: #000;
	margin-left: 1rem;
}
.now-playing-meta .current-track-meta h3:nth-child(2) {
	margin-top: -.2rem;
	font-weight: 200;
}

.player-time {
	font-weight: 800;
	color: #000;
	margin: 2rem;
}

.player-bar-wrapper {
	flex: 1;
	position: relative;
	display: flex;
	align-items: center;
}

.player-bar-progress {
	position: absolute;
	height: .3rem;
	border-radius: 10px;
	background-color: rgba(0,0,0,1);
	transition: all .1s ease;
}

.player-bar-ball {
	transition: all .1s ease;
	position: absolute;
	height: .75rem;
	width: .75rem;
	border-radius: 50%;
	background-color: #000;
}

.player-bar {
	position: relative;
	height: .2rem;
	width: 100%;
	border-radius: 10px;
	background-color: rgba(0,0,0,.2);
}


@media screen and (max-width: 900px) {
	.player {
		display: none;
	}
}

</style>