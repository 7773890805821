<template>
	<div class="wrapper" :style="store.isMobile && store.windowHeight && `height: ${store.windowHeight}px`">
		<div class="content">
			<div class="card">
				<div class="card-body">
					<div class="x-wrap" @click="close">
						<img src="/svgs/x-vector.svg">
					</div>

					<h2>{{ LOCALE[store.tunecast.lang]['LOGIN'] }}</h2>

					<div style="padding: .5rem"></div>
					<input class="form-control" :placeholder="LOCALE[store.tunecast.lang]['USERNAME'] + ' *'" v-model="username">
					<p v-if="error" style="color: red; text-align: left; font-weight: 900; font-size: 13px">{{error}}</p>
					<div v-if="store.tunecast.optin_email_enabled" style="padding: .25rem"></div>
					<input v-if="store.tunecast.optin_email_enabled" class="form-control" :placeholder="LOCALE[store.tunecast.lang]['EMAIL']" v-model="email">

					<div v-if="store.tunecast.optin_mobile_enabled" style="padding: .25rem"></div>
					<input v-if="store.tunecast.optin_mobile_enabled" class="form-control" :placeholder="LOCALE[store.tunecast.lang]['PHONE']" v-model="phone">

					<p style="color: #9A9A9A; padding-top: .45rem; cursor: pointer; font-size: 14px; text-align: left;">{{ LOCALE[store.tunecast.lang]['RECEIVEOFFERS'] }}</p>

					<div v-if="store.tunecast.optin_lists">
						<div 
							v-for="(list,key) in store.tunecast.optin_lists.split(',')" 
							:key="key"
							style="margin-top: .15rem; text-align: left; align-items: center; display: flex; justify-content: left;"
						>
							<input 
								v-model="optins[list]" 
								type="checkbox" 
								:id="list.toLowerCase().split(' ').join('-')" 
								style="cursor: pointer;"
							>
							<label :for="list.toLowerCase().split(' ').join('-')" style="color: #000; font-weight: 700; padding-left: .25rem; cursor: pointer; font-size: 14px; text-align: left;">{{list}}</label>
						</div>
					</div>

					<br />
					<p style="text-align: left; color: #9A9A9A; font-size: 8px">
						<span v-if="store.tunecast.optin_text"><span v-html="replaceURLsWithLinks(store.tunecast.optin_text)"></span>"</span>
						<span v-else>{{ LOCALE[store.tunecast.lang]['EMAILSSENTBY'] }}</span>
					</p>
					<br />
					<button class="btn" @click="setUsername">Submit</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';
import { useStore } from '@/store';
import { BACKEND_URL } from '@/services/config';
import LOCALE from '@/services/locale';

const store = useStore();

const username = ref('');
const email = ref('');
const phone = ref('');
const error = ref('');
const optins = ref({});

onMounted(() => {
	if(!store.tunecast.optin_lists) return;
	store.tunecast.optin_lists.split(',').forEach(o => optins.value[o] = false);
	console.log(optins.value)
});

const props = defineProps(['submit'])
const emit = defineEmits(['close']);
let regexp = /^[a-zA-Z0-9\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]*$/;

const resetValues = () => {
	username.value = '';
	email.value = '';
	phone.value = '';
	error.value = '';
}
const close = () => {
	resetValues()
	emit('close')
};

function replaceURLsWithLinks(str) {
    // Regular expression to match URLs
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

    // Replace URLs with anchor tags
    return str.replace(urlRegex, function(url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
}

function setUsername() {
	error.value = '';
	console.log('testing...', username.value, regexp.test(username.value))
	if(!regexp.test(username.value))
		return error.value = LOCALE[store.tunecast.lang]['ONLYALPHANUMERIC'];
	if(username.value.length < 4)
		return error.value = LOCALE[store.tunecast.lang]['USERNAMELENGTH']; // 'Username must be longer than 4 characters.'

	let _optins = Object.keys(optins.value).filter(k => optins.value[k]);
	fetch(`${BACKEND_URL}/optin`, {
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ 
			email: email.value, 
			phone: phone.value,
			name: username.value,
			ns: store.tunecast.subdomain,
			optins: _optins
		}),
    });

	props.submit({
		username: username.value,
		email: email.value
	})

	resetValues()
}

</script>

<style type="text/css" scoped>
.x-wrap {
	position: absolute;
	left: .8rem;
	top: 1rem;
	background-color: #EBEBEB;
	display: inline-block;
	padding: .1rem .54rem;
	border-radius: 50%;
	transition: .1s ease all;
}

.x-wrap img {
	height: 8px;
	position: relative;
	top: -1px;
	left: 0px;
}

.x-wrap:hover {
	opacity: .7;
	cursor: pointer;
}

.wrapper {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 100vh;
	width: 100vw;
	z-index: 5;
	backdrop-filter: blur(10px);
}

.content {
	text-align: center;
}


.card {
	position: relative;
	background-color: #fff;
	min-height: 200px;
	width: 22rem;
	border-radius: 20px;
	color: #000;
}

.card-body {
	position: relative;
	padding: 1.5rem;
}


.card .btn {
	background: #000;
	color: #fff;
	border: none;
	width: 100%;
	border-radius: 5px;
	padding: .85rem;
	transition: .2s ease all;
}

.card h2 {
	/*padding-top: 1.5rem;*/
	margin: .5rem;
}

.btn p {
	padding: 0;
	margin: 0;
	font-size: .75rem;
	font-weight: 700;
}

.btn:hover {
	opacity: .9;
	cursor: pointer;
}

.form-control {
	background-color: #EBEBEB;
	width: 100%;
	box-sizing: border-box;
	border: none;
	padding: .85rem;
	color: #000;
	border-radius: 5px;
	outline: none !important;
}
</style>