<template>
	<div id="exp-wrapper" class="wrapper" :style="`${store.isMobile && store.windowHeight && `height: ${store.windowHeight}px`}`">
		<Visualizer v-if="!store.livestreaming" />
		<LiveStream />
		<FreeSpotifyModal v-if="showSpotifyModal" @close="toggleFreeSpotifyModal" />

		<Content />
		<Chat />
		<Player />
	</div>
</template>

<script setup>
import { watch, onMounted, ref } from 'vue';
import { useStore } from '@/store'
import { storeToRefs } from 'pinia';
const store = useStore()

// import LiveStream from './components/LiveStream';

import ifvisible from "ifvisible.js";
ifvisible.setIdleDuration(120);

import Content from './components/Content.vue';
import Player from './components/Player.vue';
import Chat from './components/Chat.vue';
import Visualizer from './components/Visualizer.vue';
import FreeSpotifyModal from './components/FreeSpotifyModal.vue';
const showSpotifyModal = ref(store.isFree);

const toggleFreeSpotifyModal = () => showSpotifyModal.value = false;
console.log(toggleFreeSpotifyModal)
ifvisible.on("blur", function () {
	if (store.isMobile) window.socket.emit("blur");
});

ifvisible.on("focus", function () {
	if (store.isMobile) window.socket.emit("focus");
});

const { state } = storeToRefs(store);

let playAudio;

onMounted(async () => {
	const spotifyService = await import('@/services/spotify-service');
	playAudio = spotifyService.playAudio;
})

watch(state, () => {
	if(state.value !== 2) return;
	console.log('state updated!')
	playAudio();
})
</script>

<style type="text/css" scoped>
* {
	padding: 0;
	margin: 0;
	border: 0;
	font-family: Helvetica;
}

body {
	background: #000;
}

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: calc(100vh - 4.8rem);
	width: 100vw;
}


/* ============
 * 
 * other stuff
 *
 * =============
 */
.btn {
	background: rgba(0,0,0,.5);
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: .6rem 1.65rem;
	transition: .1s ease all;
}

.btn:hover {
	opacity: .85;
	cursor: pointer;
}

.btn-gray {
	padding: .3rem .2rem;
	background: rgba(255,255,255,.2)
}

.btn.active {
	background: #000;
}

/**
 * 
 * MOBILE
 *
 */

.mobile-divider {
	position: absolute; 
	left: -50px; 
	width: 140%; 
	height: .15rem; 
	top: 10px; 
	background-color: #17171B; 
	z-index: -5;
}

.on-air {
	display: flex; 
	align-items: center; 
	justify-content: center; 
	text-align: center; 
	color: #E34141; 
	font-weight: 600
}

.resync-btn {
	align-items: center; 
	justify-content: center; 
	text-align: center;
	font-weight: 600;
}

.resync-btn img {
	height: 9px;
	margin-right: .3rem;
}

.on-air-wrap-outer, .on-air-wrap-inner {
	position: relative; 
	justify-content: center; 
	align-items: center; 
	display: flex; 
	flex-direction: row; 
}

.on-air-wrap-outer {
	width: 100%;
}

.on-air-wrap-inner {
	padding: 0 .8rem; 
	background-color: #000; 
	gap: 20px;
}


.mobile-tracklist {
	position: relative; 
	width: 100vw; 
	left: -2rem; 
	display: flex; 
	justify-content: left; 
	gap: 1rem; 
	overflow: scroll;
}

#mobile-tracklist-buffer {
	display: inline-block; 
	min-width: 1rem; 
	height: 1rem; 
	background-color: #000;
}


.mobile-only {
	display: none!important;
}

@media screen and (max-width: 960px) {
	.tracklist {
		width: 448px;
	}
}

@media screen and (max-width: 900px) {
	.desktop-only {
		display: none!important;
	}

	.mobile-only {
		display: initial!important;
	}

	.resync-btn {
		display: flex!important;
	}

	.wrapper {
		height: 100vh;
	}
	.content {
		display: none;
	}

	.player {
		display: none;
	}

	.chat-wrapper {
		padding: 0 2rem;
	}
	.chat {
		width: 100%;
		float: none;
	}
}
</style>