<template>
	<LoginModal 
		v-if="showLoginModal" 
		:submit="onSubmit" 
		@close="toggleLoginModal"
	/>
	<div 
		class="wrapper" 
		id="login-wrapper"
		:style="`${store.tunecast.home_background_image && `background-image: url(${store.tunecast.home_background_image}); `}${store.isMobile && store.windowHeight && `height: ${store.windowHeight}px; `}`"
		>
		<div class="content" id="login-content">
			<div v-if="store.tunecast.home_logo_image">
				<!-- image -->
				<img class="login-image" id="login-image[login_logo]" :src="`${store.tunecast.home_logo_image}`">
			</div>

			<h2 style="max-width: 90vw; margin: auto;">{{store.tunecastName}}</h2>
			<p style="font-size: 18px" v-if="store.countdownStr">{{store.countdownStr}}</p>
			<br />
			<div>
				<button class="btn" @click="authSpotify" id="login-button[spotify]">
					<p v-html="store.tunecast && store.tunecast.lang && LOCALE[store.tunecast.lang]['LOGINW']"></p>
					<p><img src="/svgs/spotify.svg"></p>
				</button>
				<button class="btn" @click="authApple" id="login-button[apple]">
					<p v-html="store.tunecast && store.tunecast.lang && LOCALE[store.tunecast.lang]['LOGINW']"></p>
					<p><img src="/svgs/apple.svg"></p>
				</button>
			</div>
		</div>

		<div class="footer">
			<ul v-if="store.tunecast">
				<li><a :href="store.tunecast.custom_terms" target="_blank">Terms of Service</a></li>
				<li><a :href="store.tunecast.custom_privacy" target="_blank">Privacy</a></li>
			</ul>
		</div>
	</div>
</template>
<style scoped>
	.footer {
		position: absolute;
		bottom: .5rem;
		left: .5rem;
	}

	.footer li {
		display: inline;
		margin-right: .5rem;
		font-size: .85rem;
	}

	.footer li a {
		color: #fff;
		transition: .2s ease all;
	}

	.footer li a:hover {
		opacity: .8;
	}

	.login-image {
		width: 30vw;
    height: auto!important;
	}

	@media screen and (max-width: 800px) {
		.login-image {
			width: 90vw;
			height: auto!important;
		}
	}
</style>

<script setup>
import { ref, defineEmits, onMounted } from 'vue';
// import { getNamespace, getCountdownString } from '@/utils';
import { BACKEND_URL } from "@/services/config";
import LoginModal from './components/LoginModal';
import { useStore } from '@/store'
import { storeToRefs } from 'pinia';
import LOCALE from '@/services/locale';
console.log('LOCALE', LOCALE)

let initializeSpotify, playAudio;

// need to do this because of pinia nonsense
onMounted(async () => {
	// import { initializeSpotify, playAudio } from '@/services/spotify-service';
	const spotifyService = await import('@/services/spotify-service');

	initializeSpotify = spotifyService.initializeSpotify;
	playAudio = spotifyService.playAudio;
})


// access the `store` variable anywhere in the component ✨
const store = useStore()
const { state } = storeToRefs(store)
const showLoginModal = ref(false);

const emit = defineEmits(['authorized']);
const toggleLoginModal = () => showLoginModal.value = !showLoginModal.value;

const onSubmit = (opts) => {
	store.username = opts.username;
	store.email = opts.email;

	// post somewhere

	if(state.value === 2)
		playAudio()

	toggleLoginModal()
	emit('authorized');
}

/**
 *
 * APPLE
 *
 */
async function authApple() {
	let authorized = await window.apple_player.authorize();
	let data = await fetch(`${BACKEND_URL}/token`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			dsp: "apple",
			access_token: authorized,
		}),
	});

	data = await data.json();
	if (!data.success) return;
	window.socket.emit("token", data.token);

	store.DSP = 'APPLE';
	store.accessToken = authorized;
	// document.cookie = "tunecast.token=" + data.token;

	toggleLoginModal()
}

/**
 *
 * SPOTIFY
 *
 */
let popup;

window.addEventListener("message", handleMessage, false);
async function handleMessage(e) {
	if (!e.data) return;

	let data;
	try {
		data = JSON.parse(e.data);
	} catch (err) {
		return;
	}

	if (!data.access_token) return;


	// const spotifyMeData = await (await fetch("https://api.spotify.com/v1/me", {
	// 	headers: { authorization: `Bearer ${data.access_token}` },
	// })).json();
	// let spotifyEmail = spotifyMeData.email;

	// const modData = await (await fetch(`${BACKEND_URL}/moderator?email=${spotifyEmail}`)).json();
	// store.modSecret = modData.secret;

	let tData = await fetch(`${BACKEND_URL}/token`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			dsp: "spotify",
			access_token: data.access_token,
			refresh_token: data.refresh_token,
		}),
	});

	tData = await tData.json();

	// document.cookie = "tunecast.token=" + tData.token;
	window.socket.emit("token", tData.token);
	popup.close();
	store.DSP = 'SPOTIFY';
	store.isFree = data.free;
	store.accessToken = data.access_token;
	initializeSpotify(data.access_token);


	// console.log(checkSavedTracks)
	// let checked = await checkSavedTracks(data.access_token, store.tracks.map(t => t.spotify_id))
	// checked.forEach((v,i) => store.tracks[i].saved = v);

	toggleLoginModal();
}
async function authSpotify() {
	popup = window.open(
		`${BACKEND_URL}/oauth/spotify`,
		"Login with Spotify",
		"width=800,height=600"
	);
}
</script>

<style type="text/css" scoped>
.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 100vh;
	width: 100vw;
	perspective: 1500px;
	overflow: hidden;
	/*background-image: url(/lana.jpeg);*/
	transition: .2s ease filter;

	background-position: center;
	background-size: cover;
}

.wrapper.overlay-active {
	filter: blur(10px);
}

.content {
	text-align: center;
}

.content img {
	height: 1.5rem;
}

.btn {
	background: #fff;
	color: #000;
	border: none;
	border-radius: 5px;
	padding: .3rem 2.25rem;
	margin: 0 .5rem;
	transition: .2s all ease;
}

.btn:hover {
	opacity: .8;
	cursor: pointer;
}

.btn p {
	padding: 0;
	margin: 0;
	font-size: .75rem;
	font-weight: 700;
}
</style>